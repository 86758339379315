.change-password-content{
    display: flex;
    justify-content: center;
}
.change-password-content-inner{
    margin: 0 auto;
    display: block;
    
}

.change-password-header h1{
    font-size: 1.5em;
    margin-bottom: 0px;
    display: inline-block;
}

.change-password-header h3{
    font-size: 1em;
    font-weight: normal;
    color: var(--darker-grey);

    margin-top: 5px;
}

.change-password-input{
    display: block;
    height: 20px;
    font-size: 1em;
    background-color: var(--lighter-grey);
    margin-top: 10px;
    margin-bottom: 5px;
    border: none;
    border-radius: 20px;

    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;

    width: calc(100% - 30px);
    
}

.change-password-input:focus{
    outline: none;
}

.confirm-pass{
    margin-top: 10px;
}

#confirm-loading{
    margin-left: 10px;
    display: none;
}

.change-password-warning, .change-password-success{
    position: relative;
    text-align: center;
    display: none;
    width: 100%;
}

.change-password-warning{
    color: #e74c3c;
}

.change-password-success{
    color: #2ecc71;
}

#warning-text, #success-text{
    margin-left: 5px;
}

