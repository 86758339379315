*{
    --input-height: 40px;
    --input-width: 300px;
}
.admin-login-page-content{
    display: flex;

    width: 100%;
    height: 100%;
    
    justify-content: center;
}

.admin-login-page-wrapper{
    padding: 0;
    width: calc(var(--input-width) + 50px);
}

.admin-login-page-header{
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.admin-login-page-header p {
    color: var(--darker-grey);
}

.admin-login-page-header h1{
    margin: 0;
    text-align: center;
    font-weight: normal;
}

.admin-login-page-interaction{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-login-wrapper input[type="test"]{
    position: relative;
}

.admin-login-wrapper{
    font-family: "FontAwesome";
}

.user-input{
    font-family: 'FontAwesome', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    position: relative;
    content: "\f007";
}

.password-input{
    font-family: 'FontAwesome', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    position: relative;
    content: "\f084";
}

.user-input, .password-input, .admin-login-btn{
    height: var(--input-height);
    width: var(--input-width);
    font-size: 1em;
    border-radius: calc(var(--input-height) / 2);
}

.user-input:focus, .password-input:focus, .admin-login-btn:focus{
    outline: none;
    border: none;
}

.user-input, .password-input{
    display: block;

    border: none;
    background: var(--lighter-grey);
    
    margin-top: 10px;
    margin-bottom: 10px;

    padding-left: 20px;
}

.admin-login-btn{
    display: flex;
    justify-content: center !important;
    align-items: center !important;

    width: calc(var(--input-width) + 20px);
    float: left;
}

#admin-login-loading{
    margin-left: 10px;
    display: none;
}

#admin-login-loading.show{
    display: inline-block;
}

.admin-login-error-msg{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    text-align: center;
}

.admin-login-error-msg div{
    display: none;
    font-size: .95em;
    font-weight: bold;
}

.admin-login-error-msg div.show{
    display: block;
}


.admin-login-error-msg #invalid-creds{
    color: #e74c3c;
}

.ladmin-ogin-error-msg #server-unavailable{
    color: #e67e22;
}

.admin-error-icon, .admin-warning-icon{
    margin-right: 10px;
}