.tutorial-side-bar {
    width: 20vw;
    height: 100%;
    overflow: scroll;
    background-color: #dddddd;
    display: inline-block;
}
.tutorial-side-bar ul {
    list-style: none;
    margin-left: -10px;
}
.tutorial-side-bar ul li {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 5px solid var(--dark-grey);
    margin-bottom: 10px;
    color: var(--darker-grey);
}

.tutorial-side-bar ul li:hover {
    cursor: pointer;
    border-left: 5px solid var(--darker-grey); 
}

.tutorial-side-bar ul li.selected{
    font-weight: bold;
    border-left: 5px solid var(--darker-grey);
}