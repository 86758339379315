.info-bubble-wrapper{
    display: inline-block;
}

.info-bubble-wrapper p {
    margin-left: 10px;
    color: var(--dark-grey);
}

.tooltip{
    width: 200px;
}
