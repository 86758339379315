.contact-content {
  height: 100%;
  overflow-y: scroll;
}
.contact-row {
  max-width: 1020px;
}

.contactsection {
  width: 40%;
  margin-top: 40px;
  border: 1px solid #000000;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 10px;
}
.contactleft {
  float: left;
}
.contactright {
  float: right;
}
