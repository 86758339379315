.report-gen{
    position: absolute;
    z-index: 100;
    height: calc(100% - var(--nav-height));
    width: 100%;
    top: var(--nav-height);
    left: 0;
    background-color: rgba(255,255,255,.9);
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.report-gen-contents{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.report-preview-wrapper{
    display: inline-block !important;
    text-align: center;
    padding: 20px;
    height: 100%;
}

.report-preview-wrapper h1{
    margin: 0 auto;
}

.report-preview{
    border: 2px dashed var(--darker-grey);
    margin-top: 5px;
    background-color: white;
    display: block;
    overflow-y: scroll;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
}

.report-preview-params{
    height: 30%;
    width: 97%;
    margin: 0 auto;
    border-bottom: 1px solid var(--light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
}

.report-preview-params-child{
    width: 30%;
    height: calc(90%);
    padding: 5px;
    display: inline-block;
    text-align: left;
}

.report-preview-wrapper h4{
    margin: 0;
}

#report-preview-params-img img{
    max-height: 80%;
    width: auto;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 5px;
}

/*
#report-preview-params-location iframe{
    height: 150px;
    width: 180px;
    border: none;
}
*/

#report-preview-params-location .location-label{
    border-bottom: 1px solid black;
    color: var(--darker-grey);
    height: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#report-map{
/*
    this matched the aspect ratio of the map in the sidebar
*/

    height: 110px;
    width: 180px;
}

#report-preview-params-keywords {
    overflow-y: hidden;
}

#report-preview-params-keywords p{
    margin-top: 5px;
    color: var(--darker-grey);
    overflow-wrap: break-word;
    max-height: 90%;
    overflow: hidden;
}

.report-preview-params.false, .report-preview-params-child.false, .report-preview-notes.false{
    display: none;
}

.report-preview-notes{
    height: 90px;
    width: 97%;
    margin: 0 auto;
    margin-bottom: 36px;
    margin-top: 5px;
    text-align: left;
    border-bottom: 1px solid var(--light-grey);
}

.report-preview-notes h4{
    display: inline-block;
}

#report-notes-counter{
    margin-top: 0px;
    margin-left: 10px;
    display: inline-block;
    color: var(--light-grey);
    font-size: .9em;
}

#report-notes{
    display: block;
    height: 100%;
    width: 100%;
    resize: none;
    font-size: 1em;
}

#report-notes:focus{
    outline: none;
}

.report-preview-notes textarea{
    position: relative;
    background-color: white;
    border: none;
    height: 70%;
    line-height: 20px; 
}

.report-preview-images{
    padding: 5px;
    text-align: left;
}

.report-preview-images h4{
    text-align: left;
}

.report-preview-images-inner{
    margin-left: 20px;
}

.report-preview-images-inner h5{
    color: var(--darker-grey);
}

.report-interactions{
    display: inline-block !important;
    padding: 20px;
    background-color: white;
    height: 90%;
    border-radius: 10px;

    margin-left: 10px;

    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
}

.report-interactions-child{
    width: 80%;
    height: 45%;
    display: block;

    margin: 0 auto;
    margin-top: 5px;

    text-align: left;

    border-bottom: 1px dashed var(--light-grey);

    overflow-y: scroll;
}

.report-interactions-child h2{
    margin-top: 0px;
    text-align: center;
}

.report-rearrange{
    display: block;
    overflow-y: scroll;
}

.report-options-child{
    font-size: 1.2em;
    color: var(--darker-grey);
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.report-options-child p{
    margin: 0;
    margin-left: 10px;
}

.report-generate{
    background-color: #3498db;
    
    width: 40%;

    padding: 10px;
    margin: 0 auto;
    margin-top: 10px;

    color: white;
    font-weight: bold;

    border-radius: 5px;
}

.report-generate:hover{
    background-color: #2980b9;
    cursor: pointer;
}

#gen-report-btn-label{
    margin-left: 10px;
}

.report-gen-exit{
    position: absolute;
    top: 16px;
    right: 40px;
    font-size: 2em;
    font-weight: bold;
    color: var(--darker-grey);
}

.report-gen-exit:hover{
    cursor: pointer;
}

.report-hotel-section{
    display: table;
    page-break-inside: avoid;
}

@media print {
    * {
      -webkit-transition: none !important;
      transition: none !important;
    }
    .content-section * {
        display: none;
    }

    .content-section {
        display: none;
    }

    body {
        overflow-x: visible !important;
        overflow-y: visible !important;
    }

/*
    .report-preview *{
        visibility: visible !important;

    }

    .report-preview {
        overflow-y: visible;
        width: 100vw !important;
    }
*/
    .report-gen {
        display: none;
    }

    #print-media-wrapper {
        display: block !important;
        width: auto;
        float: none;
        position: static;
        overflow: visible;
        margin-left: 80px;
    }

/*
    #show-on-print {
        display: block !important;
        width: auto;
        float: none;
        position: static;
        overflow: visible;
        margin-left: 80px;
    }
*/
    
/*
    #print-media-wrapper{
        width: auto;
        float: none;
        position: static;
        overflow: visible;
        margin-left: 80px;
    }
*/
}
