.history-entry{
    height: 100px;
    width: 100%;

    margin-bottom: 10px;

    padding-bottom: 10px;
    border-bottom: 2px solid var(--lighter-grey);
}

.history-entry-img-wrapper{
    display: inline-block;
    vertical-align: top;

    height: 100%;
    width: 100px;

    margin-right: 10px;

    text-align: center;
}

/* defined an inner div within the wrapper so we can use flex boxes to
 * vertically align images
 */
.history-entry-img-wrapper-inner{
    display: inline-flex;
    height: 100%;
    width: 100%;

    justify-content: center;
    align-items: center;
}

.history-entry-img{
    /* fit as much space as possible with maintaining aspect ratio */
    max-height: 100%;
    max-width: 100%;
}

.history-entry-map-wrapper{
    display: inline-block;
    vertical-align: top;

    height: 100%;
    width: 175px;
    background-color: grey;
    margin-right: 10px;
    pointer-events: none;
}



.google-maps-link{
    display: none !important;
}

/* all this does is hide the google logo, this is hacky but there's very few
 * classes assigned to elements so this is the best I could come up with to hide
 * it
 */
.gm-style div a div img{
    display: none;
}


.history-entry-info{
    position: relative;
    display: inline-block;
    
    vertical-align: top;

    height: calc(100% - 5px);
    width: calc(100% - 295px);
    
    padding-top: 5px;

    color: var(--darker-grey);
}

.history-entry-info-child{
    display: flex;
    margin-top: 5px;
}

.history-entry-info-child.bottom{
    position: relative;

    position: absolute;
    bottom: 5px;
    left: 0;
}

.search-keywords-label{
    font-weight: 500;
    font-size: .95em;
}

.search-keywords-value{
    display: inline-block;
    font-size: .95em;
    margin-left: 5px;

    width: 50% !important;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.search-time-value{
    font-size: .95em;
    color: var(--dark-grey);
}