.error-page-content{
    display: flex;

    width: 100%;
    height: 100%;
    
    justify-content: center;
}

.error-page-wrapper{
    padding: 0;
    padding-top: 20px;
    width: calc(var(--input-width) + 50px);
}

.link{
    margin-top: 10px;
}