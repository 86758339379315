nav {
  padding: 0 15px;
  background: var(--tcam-blue);
}

.nav-item a {
  color: white;
  text-decoration: none;
}

.menu,
.submenu{
  list-style-type: none;
}

.logo {
  font-size: 20px;
  padding: 7.5px 10px 7.5px 0;
}

#navlogo {
    height: 20px;
}

.nav-item {
  padding: 10px;
}

.nav-item a:hover,
.nav-item a:hover::after {
  color: #ccc;
}

/* collapsed menu */

.menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.menu li a{
  display: block;
  padding: 5px 5px;
}

.menu li.subitem a{
  display: block;
  padding: 5px 5px;
}

.toggle {
  order: 1;
  font-size: 20px;
}

#toggle-icon {
 color: white;
}

.nav-item {
  order: 2;
  width: 100%;
  text-align: center;
  background: var(--tcam-blue);
  z-index: 1;
  display: none;
}

.active .nav-item {
  display: block;
}

/* submenus */

.submenu {
   display: none;
}

.submenu-active .submenu {
  display: block;
  padding-inline-start: 0;
}

.has-submenu > a::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  line-height: 16px;
  font-weight: 900;
  color: white;
  padding-left: 5px;
}

.subitem a{
  padding: 5px 10px;
}

.submenu-active {
  background-color: var(--tcam-blue-dark);
  border-radius: 3px;
}

/* Full Size menu */

@media all and (min-width: 928px) {
  
  .menu {
    justify-content: center;
  }
  
  .logo {
    flex: 1;
  }
    
  .nav-item {
    order: 1;
    position: relative;
    display: block;
    width: auto;
    text-align: right;
  }
  
  .submenu-active .submenu {
    display: block;
    position: absolute;
    right: 0px;
    top: 48px;
    padding: 12px;
    background: var(--tcam-blue-dark);
  }
  
  .toggle {
    display: none;
  }
  
  .submenu-active {
    border-radius: 0;
  }
  
}
