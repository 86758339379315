*{
    --panel-color: #383838;
    --panel-color-dark: #222222;
    --panel-switch-color: #848484;
    --panel-switch-bg: #5D5D5D;
    --panel-switch-bg-dark: #505050;
}

.result-panel{
    height: 0px;
    width: 100%;
    min-width: 640px;
    background-color: var(--panel-color);
    left: 0px;;
    transition: .4s height ease;
    display: none;
    position: absolute;
    vertical-align: top;
    color: white;
}

.result-panel.show{
    z-index: 50;
    height: var(--panel-height);
    display: block;

}

.exit-panel{
    position: absolute;
    left: 10px;
    top: 10px;
    color: var(--dark-grey);
    font-size: 1.2em;
    font-weight: bold;
}

.exit-panel:hover{
    cursor: pointer;
}

.panel-left, .panel-right{
    display: inline-flex;
    height: 100%;
    margin: 0;
    align-items: center;
    vertical-align: top;
}

.panel-left{
    width: calc(28% - 1px);
}

.panel-split{
    width: 2px;
    height: 100%;
    background-color: var(--panel-switch-color);
    display: inline-block;
}

.panel-right{
    display: inline-block;
    width: calc(60% - 1px);
}

.panel-switch{
    background-color: var(--panel-switch-bg);
    color: var(--panel-switch-color);
    font-size: 1.2em;
    padding: 3px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
    border: none;
}

.panel-switch:hover{
    cursor: pointer;
    opacity: .8;
}

.panel-switch:focus{
    outline: none;
}

.panel-switch.left{
    position: absolute;
    right: calc(100% - 36px);
}

.panel-switch.right{
    position: absolute;
    right: 20px;
}


.panel-main-image-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    margin-right: 10px;

}

.panel-main-image-wrapper.hosted{
    height: 90%;
    width: 80%;
}

.panel-main-image{
    max-width: 100%;
    max-height: 100%;
}


.panel-main-image.hosted{
    /* transform: rotate(90deg) !important; */
    max-height: 100%;
    max-width: 100%;
}

.panel-main-image.na, .panel-main-image.hosted.na{
    display: none;
}

.rotate-panel-image{
    position: absolute;
    left: calc(32% - 30px);
    bottom: 18px;
    color: var(--panel-switch-color);
}

.rotate-panel-image:hover{
    cursor: pointer;
}

.rotate-panel-image.na{
    display: none;
}

.flag-panel-image{
    position: absolute;
    left: calc(32% - 120px);
    top: 10px;
    color: var(--panel-switch-color);  
}

.flag-panel-image:hover{
    cursor: pointer;
}


.flag-panel-image-icon{
    margin-right: 10px;
}


.panel-right .panel-heading{
    display: inline-block;
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.panel-heading-wrapper{
    display: flex;
    align-items: center;
    padding: 0px 4px 0px 4px;

}

.panel-heading h1{
    font-size: 1.5em;
    font-weight: normal;
/*
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
*/
}

.panel-options-container{
    display: flex;
}

.panel-options-btns{
    margin-left: auto;
    white-space: nowrap;
}

.panel-hotel-room{
   font-size: .5em;
   display: block;
   color: var(--light-grey);
}

.panel-hotel-link{
    color: white;
    text-decoration: none;
    font-size: 0.9em;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
    border-radius: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-height: 25px;
    min-width: 25px;
    background-color: var(--panel-switch-bg);
}

.panel-hotel-link:hover{
    background-color: var(--panel-switch-bg-dark);
}

.btn-info-label{
    margin-right: 10px;
}

.panel-more{
    height: 60%;
    width: 88%;
    margin-left: 10px;
    margin-top: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-right: 2px solid var(--panel-switch-color);
}

.more-table{
    height: 100%;

}

.more-table tbody{
    overflow-y: hidden;
}

.more-table tr{
    height: 50%;
}

.more-table tr td{
}

td.active {
  border: 1px solid green;
}

.more-images-elem-wrapper{
    border-right: 1px solid rgba(255,255,255,.1);
    padding: 0px 4px 0px 4px;
    position: relative;
}

.more-images-elem-report-add{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: .5;
    color: white;
    display: block;
    margin: 0 auto;
}

.more-images-elem-report-add:hover{
    cursor: pointer;
    opacity: 1;
    color: rgba(91, 177, 219, .5);
}

.more-images-elem-report-add.active{
    opacity: 1;
    color: var(--tcam-blue);
}

.more-images-elem.na{
    display: none;
}

.more-images-error{
    display: none;    
    min-width: 130px;
    height: 100px;
    margin: 10px;    
    position: relative;
    color: var(--dark-grey);
    border: 2px dashed var(--dark-grey);
    padding-left: 5px;
    padding-right: 5px;
    z-index: -1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.more-images-error.na{
    display: inline-flex;
}

.panel-error-text{
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5em;
    border: 2px dashed white;
    width: 100%;
    height: 100%;
    margin: 10px;
}

.panel-error-text.na{
    display: flex;
}

.panel-score{
    font-size: 1em;
    margin-top: -20px;
    color: var(--dark-grey);
}

.panel-toggle-breakdown{
    font-weight: normal;
    margin-left: 5px;
    font-size: .9em;
}

.panel-toggle-breakdown:hover{
    cursor: pointer;
}

.panel-toggle-breakdown-icon{
    transform: rotate(0deg);
    margin-left: 5px;
}

.panel-toggle-breakdown-icon.toggled{
    transform: rotate(180deg);
}

.breakdown{
    display: none;
    position: absolute;
    margin-top: 5px;
    border-top: 1px solid var(--darker-grey);
    width: 100px;
    background-color: var(--panel-color-dark);
    z-index: 10;
    padding: 5px;
    font-size: .9em;
}

.breakdown.display{
    display: block;;
}


.breakdown span{
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.breakdown-score{
    display: inline-block;
    font-weight: normal;
    
}


.more-images-elem{
    height: 100px;
    width: auto;
    max-width: 160px;
}


.rps-wrapper{
    position: absolute;
    display: inline-flex;
    height: 100%;
    width: auto;
    vertical-align: top;
    right: 0px;
    align-items: center;
}

.load-hotel-images-parent{
    margin-left: 50px;
    text-align: center;
    font-style: italic;
}

.load-hotel-images{
    font-size: 5em;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}

.more-images-elem-inner-wrapper{
    display: inline-block;
    position: relative;
    text-align: right;
}

.hotel-img-grid-container{
    display: grid;
    grid-row-gap: 6px;
    grid-column-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.hotel-img-grid-item{

}
