*{
    --input-height: 40px;
    --input-width: 300px;
}
.home-page-content{
    display: flex;

    width: 100%;
    height: 100%;

    justify-content: center;
}

.home-page-wrapper{
    padding: 0;
    padding-top: 20px;
    width: calc(var(--input-width) + 50px);
}

.home-page-header{
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: x-large;
}

.home-page-header p {
    color: var(--darker-grey);
}

.home-page-header h1{
    margin: 0;
    text-align: center;
    font-weight: normal;
}

.home-page-interaction{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-wrapper input[type="test"]{
    position: relative;
}

.login-wrapper{
    font-family: "FontAwesome";
}

.user-input{
    font-family: 'FontAwesome', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    position: relative;
    content: "\f007";
}

.password-input{
    font-family: 'FontAwesome', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    position: relative;
    content: "\f084";
}

.user-input, .password-input{
    display: block;

    border: none;
    background: var(--lighter-grey);

    margin-top: 10px;
    margin-bottom: 10px;

    padding-left: 20px;
}

.login-btn{
    display: flex;
    justify-content: center !important;
    align-items: center !important;

    float: left;

    border: none;
    background: url("/img/btn_google_signin_dark_normal_web@2x.png");
    /* Must manually resize to be large enough for background photo */
    width: 382px;
    height: 92px;
}

.login-btn:hover{
    background: url("/img/btn_google_signin_dark_focus_web@2x.png");
}

.login-btn:active{
    background: url("/img/btn_google_signin_dark_pressed_web@2x.png");
}

.login-ms-btn{
    display: inline-flex; /* or 'flex' if you prefer */
    justify-content: center;
    align-items: center;
    border: none;
    background: url("/img/ms-symbollockup_signin_light.png");

    /* Must manually resize to be large enough for background photo */
    width: 382px;
    height: 73px;
}

.login-ms-btn:hover{
    background: url("/img/ms-symbollockup_signin_focus.png");
}

.login-ms-btn:active{
    background: url("/img/ms-symbollockup_signin_dark.png");
}


#login-loading{
    margin-left: 10px;
    display: none;
}

#login-loading.show{
    display: inline-block;
}

.login-error-msg{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    text-align: center;
}

.login-error-msg div{
    display: none;
    font-size: .95em;
    font-weight: bold;
}

.login-error-msg div.show{
    display: block;
}


.login-error-msg #invalid-creds{
    color: #e74c3c;
}

.login-error-msg #server-unavailable{
    color: #e67e22;
}

.error-icon, .warning-icon{
    margin-right: 10px;
}
