.search-page-content{
    height: 100%;
    overflow-y: hidden;
}

.search-page-content .scroll{
    height: 100%;

}

.interaction-pane{
    display: inline-block;
    height: 100%;
    width: calc(100% - var(--sidebar-width));
    vertical-align: top;
    text-align: center;
    padding-top: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.interaction-pane.overlay{
    position: absolute;
    right: 0;
    margin-left: var(--sidebar-width);
    width: calc(100%);
}

.interaction-pane-label{
    text-align: left;
    padding-left: 50px;
    margin: 0 auto;
    position: relative;
    font-weight: bold;
    white-space: nowrap;
}

#interaction-pane-label-text{
    display: inline-block;
    margin-bottom: 0px;
}

.interaction-upload{
    max-height: 400px;
    width: auto;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.interaction-upload.inline{

    vertical-align: top;
    max-width: 100%;
    height: auto;
}

#interaction-upload-wrapper{

}

.interaction-upload-inner{
    width: 100%;
    height: 100%;
}

.interaction-pane.show-results{
    height: calc(100% - 30px);
    text-align: center;
    padding: 0px;
    position: relative !important;
}

.show-results-wrapper-inner{
    margin-bottom: 50px;
}

#imgupload{
    display: none;
}

#imgUrl{
    display: inline-block;
    background: none;
    border: none;
    font-size: 1em;
    border-bottom: 2px solid var(--dark-grey);
    width: 200px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    color: var(--darker-grey);
    margin: 0 auto;
    margin-top: 20px;
}

#imgUrl::placeholder{
    color: var(--light-grey);
}

#imgUrl:focus{
    outline: none;
}


#trueSearch{
    margin-top: 50px;
    min-width: 200px;
}

.result-count{
    color: var(--dark-grey);
    margin-top: 5px;
    margin-left: 10px;
    text-align: left;
    display: block;
}

.edit-canvas{
    padding: 0px;
    float: left;
}


#canvas-wrapper{
    z-index: 0;
    display: inline-block;
}

.crop-overlay{
    border: 3px solid var(--tcam-blue);
    
    position: absolute;
    z-index: 100;

    background-color: rgba(255, 255, 255, 0.3);

    justify-content: center;
    align-items: center; 
}

.crop-dim{
    display: none;
    
}

.crop-overlay.show{
    display: inline-block;
}

.crop-overlay.hide{
    display: none;
}

.guiding-box-horizontal{
    position: absolute;
    border: 1px dashed rgba(255,255,255,.7);
    border-left: none;
    border-right: none;
    height: calc(100%/3);
    width: calc(100%);
    top: calc(100%/3);
}

.guiding-box-vertical{
    position: absolute;
    border: 1px dashed rgba(255,255,255,.7);
    border-top: none;
    border-bottom: none;
    height: calc(100%);
    width: calc(100%/3);
    left: calc(100%/3);
}

.crop-center-align{
    position: absolute;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    opacity: .7;
    top: 48%;
    left: 48%;
}

.crop-over-circle{
    position: absolute;

    height: 10px;
    width: 10px;

    border: 3px solid var(--tcam-blue);
    background-color: white;
}

.crop-over-circle.top.left:hover, .crop-over-circle.bottom.right:hover{
    cursor: nwse-resize;
}

.crop-over-circle.bottom.left:hover, .crop-over-circle.top.right:hover{
    cursor: nesw-resize;
}

.crop-over-circle.top{
    top: -9px;
}

.crop-over-circle.left{
    left: -9px;
}

.crop-over-circle.bottom{
    bottom: -9px;
}

.crop-over-circle.right{
    right: -9px;
}



.canvas-draw{
    margin: 0 auto;
    margin-top: 3px;
    margin-left: 3px;
}

/*
.canvas-draw:hover{
    cursor: none;
}
*/

.canvas-draw.mask-toggled:hover{
    cursor: none;
}

.cursor-div{
    background-color: black;
    position: absolute;
    border-radius: 50%;
}

.sketch-field-wrapper{
    border: 1px solid blue;
    display: block;
}

.edit-search-pane{
    position: absolute !important;
    left: 0;
    top:0;
    display: none;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 50px;
    overflow-y: hidden;
    background: rgba(255,255,255,.95);
}

.edit-search-pane.show{
    display: block;
}

.edit-pane-col{
    border-right: 3px dashed var(--dark-grey);
    min-width: 286px;
}

#out-canvas, #mask-canvas{
    display: none;
}

.load-search{
    font-size: 20em;
    margin-top: 100px;
    color: lightgrey;
    opacity: .4;
}

#img-upload-load{
    margin-left: 10px;
    display: none;
}


#img-upload-load.show{
    display: inline-block;
}

#draw-canvas{
    display: inline-block;
    position: relative;
}
