*{
    --panel-color: #383838;
    --panel-switch-color: #848484;
    --panel-switch-bg: #5D5D5D;
    --panel-switch-bg-dark: #505050;
}

.hp-result-panel{
    height: 0px;
    width: 100%;

    min-width: 450px;
    
    background-color: var(--panel-color);
    left: 0px;;
    transition: .4s height ease;
    display: none;
    position: absolute;
    vertical-align: top;
    color: white;
}

.hp-result-panel.show{
    z-index: 50;
    height: var(--panel-height);
    display: block;
    
}

.hp-panel-left, .hp-panel-right{
    display: inline-flex;
    height: 100%;
    margin: 0;
    align-items: center;
    vertical-align: top;
}

.hp-panel-left{
    justify-content: flex-end;
    width: calc(60% - 1px);
}

.hp-panel-split{
    width: 2px;
    height: 100%;
    background-color: var(--panel-switch-color);
    display: inline-block;
}

.hp-panel-right{
    display: inline-block;
    width: calc(40% - 1px);
}

.hp-panel-switch{
    background-color: var(--panel-switch-bg);
    color: var(--panel-switch-color);

    font-size: 1.2em;
    
    padding: 3px;
    padding-right: 5px;
    padding-left: 5px;
    
    border-radius: 5px;
    border: none;
}

.hp-panel-switch:hover{
    cursor: pointer;
    opacity: .8;
}

.hp-panel-switch:focus{
    outline: none;
}

.hp-panel-switch.left{
    position: absolute;
    right: calc(100% - 40px);
}

.hp-panel-switch.right{
    position: absolute;
    right: 25px;
}


.hp-panel-main-image-wrapper{
    display: flex;

    justify-content: center;
    align-items: center;

    height: 80%;
    width: 90%;

    margin-right: 10px;
    
}

.hp-panel-inner-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}


.hp-panel-main-image{
    max-width: calc(100% - 60px);
    max-height: 100%;
}

.hp-panel-main-image.na{
    display: none;
}

.hp-panel-right .panel-heading{
    display: inline-block;
    text-align: left;
    padding-left: 10px;
    max-width: 60%;  
}

.hp-panel-heading-wrapper{
    display: block;
    text-align: left;
    padding-left: 10px;

}
.hp-panel-heading, .hp-panel-subheading{
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.hp-panel-heading{
    font-size: 2em;
}

.hp-panel-subheading{
    font-size: 1.2em;
    color: var(--dark-grey);
}

.hp-panel-heading h2{
    font-size: 1.5em;
    font-weight: normal;
    font-style: italic;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--panel-switch-color);
}

.hp-panel-hotel-name, .hp-panel-hotel-subtext{
    margin-bottom: 10px;
}

.hp-panel-hotel-link{
    display: block;
    
    color: white;
    text-decoration: none;
    font-size: 1em;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    margin-top:5px;

    max-width: 40%;
    height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    overflow: hidden;

    text-align: center;

    border-radius: 10px;

    background-color: var(--panel-switch-bg);
}

.hp-panel-hotel-link:hover{
    background-color: var(--panel-switch-bg-dark);
}

.btn-info-label{
    margin-right: 10px;
}


.hp-rps-wrapper{
    position: absolute;
    display: inline-flex;
    height: 100%;
    width: auto;
    vertical-align: top;
    right: 0px;
    align-items: center;
}

.hp-panel-inner-wrapper{
    height: 100%;
    width: 100%;
}

.panel-main-image.na{
    display: none;
}

.panel-main-image-error{
    display: none;

    position: relative;

    margin: 0 auto;

    width: 90%;
    height: 100%;
    border: 2px dashed white;

    align-items: center;
    justify-content: center;

    font-size: 1.5em;
}

.panel-main-image-error.na{
    display: flex;
}

.hp-rotate-panel-image{
    position: absolute;
    left: calc(60% - 30px);
    bottom: 10px;
    color: var(--panel-switch-color);
}

.hp-rotate-panel-image:hover{
    cursor: pointer;
}

.hp-rotate-panel-image.na{
    display: none;
}

.hp-flag-panel-image{
    position: absolute;
    left: calc(60% - 140px);
    top: 10px;
    color: var(--panel-switch-color);  
}

.hp-flag-panel-image:hover{
    cursor: pointer;
}


.hp-flag-panel-image-icon{
    margin-right: 10px;
}

.hp-exit-panel{
    position: absolute;
    left: 10px;
    top: 10px;
    color: var(--dark-grey);
    font-size: 1.2em;
    font-weight: bold;
}

.hp-exit-panel:hover{
    cursor: pointer;
}
