*{
    --label-grey: #969595;
}

.hp-show-results{
    position: relative;
    vertical-align: top;
}

.hp-full-result-wrapper{
    display: inline-block;
    position: static;
    vertical-align: top;
}

.hp-result-wrapper{
    vertical-align: top;
    margin: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    transition: .2s -webkit-box-shadow ease, .2s box-shadow ease,
        .2s -moz-box-shadow ease;
}

.hp-result-wrapper:hover{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
}

.hp-result-wrapper.focus{
    margin-bottom: 0px;
    border-bottom: 10px solid var(--tcam-blue);
}

.hp-result-wrapper.fps.focus{
    margin-bottom: 0px;
    border-bottom: 10px solid var(--label-grey);
}

.hp-result-img{
    height: 175px;
    width: auto;
    opacity: 0;
    transition: opacity 1s ease;
}

.hp-result-img.show{
    opacity: 1;
}

.hp-result-img.na{
    display: none;
}

.hp-result-error-text{
    display: none;

    font-size: 1.5em;


    justify-content: center;
    align-items: center;

    margin-top: -20px;

    padding: 10px;
}

.hp-result-error-text.na{
    display: flex;
}



.hp-placeholder {
    height: 175px;
    width: 240px;
    background-repeat: no-repeat;

    background-position: center;
    background-color: lightgrey;

}

.hp-placeholder.na{
    width: 200px;
    display: flex;
}



.hp-placeholder.dynamic {
    width: auto;
    background-image: none;
}

.hp-result-label{
    font-weight: bold;
    color: white;
    background-color: var(--label-grey);

    text-align: left;

    padding-top: 3px;
    padding-bottom: 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hp-result-label.fps{
    background-color: var(--tcam-blue);
}

.hp-result-label-inner{
    margin-left: 5px;
}