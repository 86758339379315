.load-search{
    display: block;
    margin: 0 auto;
}

.results-top-label{
    display: block;
    height: 50px;
    margin-top: 10px;
}

.result-count{
    float: left;
}

.results-filter-indicator-wrapper{
    height: auto;
    margin-top: -36px;
}

.results-filter-indicator{
    color: white;
    font-weight: bold;
    width: 100px;
    background: #2ecc71;
    padding: 5px;
}

.disable-filter{
/*
    position: absolute;
*/
/*
    float: right;
*/
/*
    margin-top: -23px;
*/
/*
    right: 40px;
*/
    color: white;
    font-size: .9em;
    font-weight: bold;
    display: inline-block;
}

.disable-filter:hover{
    cursor: pointer;
}

.disable-filter-icon{
    margin-left: 10px;
}

.search-edit-pane-wrapper{
    position: fixed;
    
    background-color: white;

    width: calc(100% - var(--sidebar-width));
    height: calc(100% - calc(var(--nav-height) + 30px));

    top: calc(var(--nav-height) + 30px);
    z-index: 100;
}

.search-edit-pane-inner{
    position: relative;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    width: 100%;
    display: block;
}

.report-clear-wrapper{
    height: auto;
    margin-top: -42px;
}

.report-item-count, .report-clear{
    text-align: right;
    padding-right: 10px;
    font-size: .9em;
}

.report-clear{
    margin-top: 5px;
    font-size: .9em;
    color: var(--darker-grey);
}

.report-clear:hover{
    cursor: pointer;
}

.report-clear-icon{
    margin-left: 5px;
}

.api-down-msg{
    margin-top: 30px;
    text-align: center;
    color: grey;
}

.api-down-msg h2{
    margin: 0 auto;
    text-align: center;
}

.api-down-msg h3{
    color: var(--light-grey);
}

.api-down-msg h3:hover{
    cursor: pointer;
    text-decoration: underline;
}

#api-down-loader{
    margin-left: 5px;
    display: none;
}

#api-down-loader.show{
    display: inline-block;
}

.infinite-scroller{
    padding-bottom: 80px;
    position: relative;
    bottom: 80px;
    top: 10px;
}

.loading-more-results{
    position: relative;
    bottom: 60px;    
}

.results-end{
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: lighter;
    font-size: 1.2em;
    color: var(--dark-grey);
}
