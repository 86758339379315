
*{
    --unfocused-text:#7E7E7E;
}

@media screen and (min-width: 1100px) {
    *{
        --sidebar-width: 360px;
    }
}

@media screen and (max-width: 1100px) {
    *{
        --sidebar-width: 300px;
    }
}

.sidebar{
    width: var(--sidebar-width);
    height: 100%;
    background-color: var(--lighter-grey);
    display: inline-block;
    overflow-y: scroll;
}

.sidebar .option{
    font-weight: bold;
}

.reset-search{
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    color: var(--unfocused-text);
    margin-top: 10px;
    padding: 10px;
}

.reset-search:hover{
    cursor: pointer;
}


.uploaded-image, #set-location, #set-keywords{
    padding: 0px 10px 0px 10px;
}

#set-location{
    margin-bottom: 16px;
}


.image-sb-br{
    background-color: var(--light-grey);
    height: 1px;
    width: 95%;
    border: none;
    margin-top: 20px;
}

.uploaded-entry, .sidebar-canvas, .sidebar-canvas img{
    display: block;
    max-height: 160px;
/*
    margin: 0 auto;
*/
    margin-top: 20px;
    max-width: 280px;
}

.uploaded-entry{
    display: none;
}

.uploaded-image-box {
    /* min-height: 311px; */
}

.uploaded-entry.show{
    display: block;
}

.sidebar-canvas{
    position: relative;
    margin: 0 auto;
    margin-top: 0px;
    display: flex;
    justify-content: center;
}

.load-sidebar{
    color: var(--dark-grey);
    display: none;
    font-size: 130px;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 100%;
}

.load-sidebar.show{
    display: block;
}

.upload-image-sb{
    z-index: 50;
    position: absolute;
}

.canvas-image-sb{
    z-index: 51;
    position: absolute;
}

.advanced-toggler{
    margin-left: 10px;
    margin-top: 6px;
    text-align: left;
    width: 90%;
    font-style: italic;
}

.advanced-toggler:hover{
    cursor: pointer;
}

#advanced-chevron{
    color: var(--light-grey);
    margin-left: 10px;
}

#advanced-chevron.rotate{
    transform: rotate(180deg);
}

#advanced-options{
    display: none;
}

#advanced-options.display{
    display: block;
}

.advanced-options-loading-wrapper{
    display: none;
}

.advanced-options-loading-wrapper.display{
    display: inline-block;
}

#advanced-options-inner-wrapper{
    display: block;
    margin-bottom: 50px;
}

#advanced-options-inner-wrapper.hide{
    display: none;
}

.advanced-options-loading, .advanced-options-loading-text{
    font-size: 1.2em;
    display: inline-block;
    color: var(--dark-grey);
    font-weight: normal;
    margin-left: 10px;
}

.set-location-inner{
    margin-top: 10px;
    height: 200px;
    width: 100%;
}

.set-location-inner.disabled{
    pointer-events: none;
    user-select: none;
}

.map-contents{
    width: 100%;
    height: 100%;
}

.gm-style-mtc{
    display: none;
}
.gm-style-cc{
    display: none;
}


.update-map-btn-wrapper{
    position: relative;
    margin-top: 5px;
}

.update-map-btn{
    width: fit-content;
    display: inline-block;
    font-size: .9em;
    color: white;
    background: var(--light-grey);
    padding: 5px;
    width: auto;
    border-radius: 5px;
}

.update-map-btn:hover{
    background: var(--dark-grey);
    cursor: pointer;
}

.update-map-loading{
    margin-left: 5px;
    color: var(--dark-grey);
    display: none;
}

.update-map-loading.show{
    display: inline-block;
}

.map-coords{
    color: var(--darker-grey);
    font-size: .8em;
    margin-top: 5px;
    margin-bottom: -5px;
}

#map-coords{
    margin-left: 5px;
}

.keywords-input{
    display: block;
    font-size: 1em;
    width: 90%;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    color: var(--darker-grey);
}

.keywords-input::placeholder{
    color: var(--light-grey);
}

.keywords-input:focus{
   /* outline: none; */
}

.place-card, .login-control{
    border: 1px solid red !important;
}

.brightness-edit, .contrast-edit{
    margin-top: 10px;
    margin-bottom: 20px;
}

.advanced-edit-tools{
    display: none;
}

.advanced-edit .italics:hover{
    cursor: pointer;
}

.advanced-edit-tools.show{
    display: block;
}

.edit-image-search{
    color: grey;
    text-align: right;
    float: right;
    margin-right: 50px;
}

.edit-image-search:hover{
    cursor: pointer;
}

.edit-image-search .is-pen{
    margin-left: 5px;
}

.uploaded-entry-wrapper {
    max-height: 300px;   /* Cap height for very tall images */
    min-height: 160px;
    width: 280px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.uploaded-entry-wrapper.hide {
    display: none;
}

#uploaded-entry-sb {
    transition: transform .2s;
    max-height: 280px;
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    display: block;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

#uploaded-entry-sb:hover {
    cursor: zoom-in;
}

#uploaded-entry-sb:active {
    transform: scale(2) translate(120px, 0px);
    position: fixed;
    z-index: 100;
    padding:1px;
    border:1px solid var(--darker-grey);
    background-color: white;
}

