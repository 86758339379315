.report-preview-result{
    display: inline-block;
    margin: 5px;
}

.report-preview-img{
    height: 135px;
    width: auto;
    display: inline-block;
    margin-bottom: -5px;
}

.report-preview-img.na{
    display: none;
}

.report-preview-error-text{
    display: none;

    align-items: center;
    justify-content: center;

    text-align: center;

    font-size: 1.5em;
    height: 133px;
    width: 150px;

    border: 2px dashed var(--light-grey);
    border-bottom: none;
}

.report-preview-error-text.na{
    display: flex;
}
