.history-page-header{
    height: 50px;
    display: flex;

    align-items: center;
}

.history-page-header h1{
    margin: 0;
}

#header-loader{
    font-size: 1.2em;
    color: var(--light-grey);
    margin-left: 10px;
    display: none;
}

.history-page-inner{
    width: 50%;
    margin: 0 auto;
    position: relative;
}

.history-page-scroll-section{
    overflow-y: scroll;
    border-top: 2px solid grey;
    padding-left: 50px;
    padding-top: 10px;
    margin-top: 10px;
}

.history-page-body{
    margin-bottom: 20px;
    text-align: center;
}

.history-page-loader{
    color: var(--lighter-grey);
    font-size: 5em;
}

.history-page-footer{
    border-top: 2px solid grey;
    background-color: var(--lighter-grey);
    padding: 5px;
}

.history-page-footer.hide{
    display: none;
}


.history-page-footer-label, .history-page-footer-interaction{
    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--darker-grey);
}

.footer-interaction-child{
    margin-left: 10px;
    margin-right: 10px;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.footer-interaction-child:hover{
    cursor: pointer;
}

#right-seperator, #left-seperator{
    letter-spacing: 3px;
}

#right-seperator:hover, #left-seperator:hover{
    cursor: default;
}

#current-page{
    border-bottom: 2px solid grey;
    font-weight: bold;
}

.footer-btn{
    margin-left: 10px;
    margin-right: 10px;
}

.footer-btn:hover{
    cursor: pointer;
}

.history-page-number-line{
    text-align: center;
    display: inline-block;
}