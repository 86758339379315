.admin-table-wrapper {
    overflow: auto;
    padding: 1em;
    white-space: nowrap;
}

.admin-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    font-size: 1.2em;
    padding: 10px;
}

.admin-tr-header {
    background-color: #5bb1db;
    color: #ffffff;
}

.admin-tr-body {
    color: #000000;
}

.admin-tr-body:nth-child(2n) {
    background-color: #e8e8e8;
}

.admin-tr-body:hover {
    background-color: #ddd;
}

th {
    padding: 10px;
}

td {
    padding: 10px;
}
