.hpsb{
    vertical-align: top;
    width: var(--hpsb-width);
    height: 100%;

    display: inline-block;

    background-color: var(--lighter-grey);

}

.hpsb-inner{
    margin-top: 10px;
    margin-left: 10px;
}

.hpsb-header-text{
    font-size: 1.5em;
}

.hpsb-subheader{
    color: var(--darker-grey);
    font-style: italic;
    margin-bottom: 25px;
}

.hotel-location{
    border: 0;
    frame: 0;
    width: 95%;
    height: 300px;
    margin: 0 auto;
}
.hpsb-hotel-info{
    margin-top: 10px;
    font-weight: bolder;
    color: var(--darker-grey);
    padding-bottom: 5px;
    width: 95%;
    font-size: .9em;
}
    
.hpsb-info{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bolder;
    color: var(--darker-grey);
    padding-bottom: 5px;
    width: 95%;
    font-size: .9em;
}

.hpsb-info span, .hpsb-info a{
    margin-left: 5px;
    font-weight: normal;
}

.hpsb-img-count{
    font-style: italic;
    color: var(--darker-grey);
}

.space{
    margin-left: 2px;
    margin-right: 2px;
}
