.profile-page-inner{
    width: 40%;
    min-width: 500px;
    margin: 0 auto;
}

.profile-page-child{
    margin-left: 30px;
}

.profile-page-child-label{
    color: var(--darker-grey);
    margin-bottom: 0px;
}

.profile-page-child-label#api-key-label{
    margin-bottom: 10px;
}

.profile-page-child-content{
    margin-left: 10px;
    margin-top: 5px;
}

.profile-page-api-key-contents{
    background-color: var(--lighter-grey);
    color: var(--lighter-grey);
    border: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 40px;
    margin-left: 10px;
}

.profile-page-api-key-contents.active{
    color: var(--darker-grey);
    -webkit-user-select: auto; /* Safari */        
    -moz-user-select: auto; /* Firefox */
    -ms-user-select: auto; /* IE10+/Edge */
    user-select: auto; /* Standard */
}

.profile-page-api-key-contents:focus{
    border: none;
    outline: none;
}

.profile-page-api-key-view{
    display: inline-block;
    margin-left: -25px;
}

.profile-page-api-key-view:hover{
    cursor: pointer;
}

.profile-page-api-key-view .view-icon{
    color: var(--dark-grey);
}

.profile-page-api-key-view .view-icon:hover{
    color: var(--darker-grey);
}

.profile-page-api-key-last-update{
    font-style: italic;
}

.profile-page-api-key-last-update, .profile-page-api-key-regen{
    margin-left: 10px;
    font-size: .8em;
    color: var(--darker-grey);
    margin-bottom: 2px;
}

.profile-page-api-key-regen{
    margin-top: 5px;
}

.profile-page-api-key-regen{
    cursor: pointer;
}

.regen-btn{
    margin-left: 3px;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

.regen-loading{
    color: var(--dark-grey);
    margin-left: 10px;
    display: none;
}

.regen-loading.show{
    display: inline-block;
}

.profile-page-history{
    margin-top: 20px;
    font-size: 1.2em;
    font-weight: bold;
}

.profile-page-history a{
    text-decoration: none;
}

.profile-page-log-out{
    margin-top: 20px;
}

.profile-page-log-out a{
    font-size: 1.1em;
    color: var(--darker-grey);
    text-decoration: none;
}

.sign-out-btn{
    margin-left: 5px;
}

