@media screen and (min-width: 1100px) {
    *{
        --hpsb-width: 460px;
    }
}

@media screen and (max-width: 1100px) {
    *{
        --hpsb-width: 320px;
    }
}

.hotel-page-content{
    vertical-align: top;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

.hotel-results{
    display: inline-block;
    height: 100%;
    width: calc(100% - var(--hpsb-width));
    vertical-align: top;
    padding-top: 50px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    text-align: center;
    padding: 0px;
    position: relative !important;
}

.hr-inner-wrapper{
    margin-bottom: 50px;
}

.hotel-page-load{
    font-size: 20em;
    margin: 0 auto;
    margin-top: 100px;
    color: lightgrey;
    opacity: .4;
    display: block;
}

.hp-results-end{
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: lighter;
    font-size: 1.2em;
    color: var(--dark-grey);
}
