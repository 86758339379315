.results-search-bar-wrapper{
/*
    float: right;
    margin-right: 10px;
*/
    margin: 0 auto;
}

.results-search-bar-input{
    border: none;
    border-bottom: 1px solid var(--light-grey);
    font-size: 1em;
    color: var(--darker-grey);
}

.results-search-bar-input:focus{
    outline: none !important;
}

.results-search-bar-input::placeholder{
    color: var(--light-grey);
}

.results-search-bar-icon{
    color: var(--darker-grey);
}

.results-search-bar-icon:hover{
    cursor: pointer;
}
