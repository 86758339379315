*{
    --lighter-grey: #E8E8E8;
    --light-grey: #BCBCBC;
    --dark-grey: #A8A8A8;
    --darker-grey: #767171;
    --tcam-blue: #5bb1db;
    --tcam-blue-dark: #1387bf;
    --tcam-pink: #D15685;
    --tcam-pink-dark: #AD456D;
    --nav-height: 40px;
    --sidebar-width: 360px;
}

/* * { background-color: rgba(255,0,0,.2); }
* * { background-color: rgba(0,255,0,.2); }
* * * { background-color: rgba(0,0,255,.2); }
* * * * { background-color: rgba(255,0,255,.2); }
* * * * * { background-color: rgba(0,255,255,.2); }
* * * * * * { background-color: rgba(255,255,0,.2); }
* * * * * * * { background-color: rgba(255,0,0,.2); }
* * * * * * * * { background-color: rgba(0,255,0,.2); }
* * * * * * * * * { background-color: rgba(0,0,255,.2); } */


html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*.unselectable{
    -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

p a{
    margin-left: 5px;
    margin-right: 5px;
}

#root{
    height: 100%;
}

.page{
    height: 100%;
}

.content-section{
    height: calc(100% - 40px);
}

.italics{
    color: var(--light-grey);
    font-style: italic;
}

.btn-primary{
    background-color: #3498db;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    border-radius: 20px;
    font-size: 1.2em;
    display: block;
    margin: 0 auto;
    border: none;
    margin-bottom: 20px;
    text-align: center;
}

.btn-primary:hover{
    cursor: pointer;
    background-color: #2980b9;
}

.btn-primary:hover:disabled {
    cursor: not-allowed;
    background-color: #2980b9;
}

.btn-alt{
    background-color: var(--tcam-pink);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    border-radius: 20px;
    font-size: 1.2em;
    display: block;
    margin: 0 auto;
    border: none;
    margin-bottom: 20px;
}

.btn-alt:hover{
    cursor: pointer;
    background-color: var(--tcam-pink-dark);
}




.btn-primary:focus, .btn-alt:focus{
    outline: none;
}

.btn-break{
    border: 1px solid red;
}

.ul{
    text-decoration: underline;
}

.bold{
    font-weight: bold;
}
