.edit-pane{
    display: inline-block;
    vertical-align: top;
    text-align: left;
    height: 100%;
    padding-top: 100px;
    color: var(--darker-grey);
}

.left-buttons, .right-buttons{
    display: inline-block;
}

.right-buttons{
    float: right;
    margin-right: 30%;
}

.brush-edit-btns{
    margin-left: -15px;
}

.edit-btn, .undo-btn, .clear-btn, .rotate-btn{
    display: inline-block;
    margin: 10px;
    vertical-align: top;
    width: 50px;
    text-align: center;
}

button.edit-btn{
    color: var(--darker-grey);
    border: none;
    background-color: white;
    display: inline-block;
    margin: 10px;
    vertical-align: top;
    width: 50px;
    text-align: center;

}


.edit-btn:hover, .undo-btn:hover, .clear-btn:hover, .rotate-btn:hover, clear-filter-btn:hover{
    cursor: pointer;
}

.edit-btn .changes{
    width: 25px;
    margin: 5px;
    color: var(--light-grey);
    font-size: 1.5em;
}

button.edit-btn.show-children{
    border-bottom: 5px solid var(--tcam-blue);
    border-top: none;
    border-left: none;
    border-right: none;
}

button.edit-btn.show-children:focus{
    outline-color: white;
}

button.edit-btn.show-children:active{
    outline-color: white;
}

.clear-filter-btn {
    text-align: center;
    padding: 6px;
    border-radius: 6px;
    font-size: 0.8em;
    color: var(--darker-grey);
    border: 0.5px solid var(--darker-grey);
}

.brush-color-wrapper h4{
    text-decoration: underline;
    margin-bottom: 0;
}

.brush-color-option h5{
    font-style: italic;

    margin: 0;
    margin-left: 5px;
    font-size: .8rem;

    margin-top: -1rem;
}

.brush-color-option{
    height: 50px;
    width: 50px;

    display: inline-block;

    border: 1px solid var(--light-grey);
    border-radius: 50%;
    margin: 10px;
}

.brush-color-option:hover{
    cursor: pointer;
    border-bottom: 5px solid var(--lighter-grey);
}

.brush-color-option.focus, .brush-color-option.focus:hover{
    border-bottom: 5px solid var(--tcam-blue);
}

.brush-color-option.white{
    background-color: white;
}

.brush-color-option{
    background-color: black;
}

.color-switch-wrapper{
    display: flex;
    align-items: center;
}

.color-switch-label{
    display: inline-block;
    margin-right: 15px;
}

.color-switch-label.active{
    border-bottom: 3px solid var(--tcam-blue);
}

.color-switch{
    display: inline-block;
    margin-right: 15px;
}

.bottomPad{
    display: block;
    height: 10px;
}

#redo{
    float: right;
}

#rotate-btn, #crop-btn{
    padding: 0px;
}

#crop-btn.enabled{
    border: 2px solid var(--tcam-blue);
    border-radius: 5px;
}

.brush-edit{
    margin: 10px;
}

.brush-size{
    background-color: var(--light-grey);
    border-radius: 50%;
    display: inline-block;
    margin-left: 20px;
}

.advanced-edit{
    margin: 10px;
}

.mask-icon, .undo-icon, .clear-icon, .edit-icon, .rotate-icon, .more-icon,
    .finish-crop-icon, .cancel-crop-icon {
    color: var(--light-grey);
    margin: 0 auto;
    font-size: 1.7em;
    margin-top: 5px;
}

#advancedt-chevron.up{
    transform: rotate(180deg);
}

.option-pane{
    display: none;
    padding: 10px;
    padding-left: 40px;

    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    margin-right: 10px;

}

.option-pane.show{
    position: relative;
    display: block;
}

.load-search{
    margin-left: 10px;
}

#loadingsearch{
    display: none;
}

.reset-crop-btn{
    background-color: #f1c40f;
    color: white;
    width: 160px;
    text-align: center;
    display: block;
    padding: 10px;
    border-radius: 20px;
}

.reset-crop-btn:hover{
    cursor: pointer;
    background-color: #dbb20d;
}

.basic-edit{
    text-align: center;
}

.hide-btn{
    display: none;
}

.edit-btn:disabled:hover{
    cursor: not-allowed;
}

.edit-btn:disabled{
    opacity: 0.3;
}

.crop-options-wrapper{
    text-align: center;
}

.crop-intro{
    text-align: center;
    margin-bottom: 20px;
}

#trueSearch:disabled{
    opacity: 0.3;
}

#trueSearch:disabled:hover{
    cursor: not-allowed;
}
    
    
