.search-page-content{
    height: 100%;
    overflow-y: hidden;
}

.search-page-content .scroll{
    height: 100%;

}

.interaction-pane{
    display: inline-block;
    height: 100%;
    width: calc(100% - 360px);
    vertical-align: top;
    text-align: center;
    padding-top: 50px;
    overflow-y: scroll;
}

#upload-drop-area{
    margin-top: 53px;
    margin-right: 50px;
    margin-left: 50px;
    min-width: 502px;
}

#upload-drop-area.over{
    margin-top: 50px;
    padding-bottom: 30px;
    border: 3px dashed var(--darker-grey);
}

#upload-img-btn.hide{
    display: none;
}

.drop-file-label{
    display: none;
    color: var(--darker-grey);
    font-size: 1.5rem;
    font-weight: normal;
    font-style: italic;
}

.drop-file-label.show{
    display: block;
}

.interaction-pane-label{
    text-align: left;
    padding-left: 50px;
    margin: 0 auto;
    position: relative;
    font-weight: bold;
}

#interaction-pane-label-text{
    display: inline-block;
    margin-bottom: 0px;
}

.interaction-upload{
    max-height: 400px;
    width: auto;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.interaction-upload.inline{

    vertical-align: top;
    max-width: 100%;
    height: auto;
}

#interaction-upload-wrapper{
/*
    display: inline-block;
    
    
    width: 60%;
    height: 80%;
    margin-left: 10px;
*/
    


}

.interaction-upload-inner{
    width: 100%;
    height: 100%;
}

.interaction-pane.show-results{
    text-align: center;
    padding: 0px;
    position: relative !important;
}

.show-results-wrapper-inner{
    margin-bottom: 50px;
}

#imgupload{
    height: 100px;
    width: 100px;
    border: 1px solid red;
    background: lemonchiffon;
}

#imgUrl{
    display: inline-block;
    background: none;
    border: none;
    font-size: 1em;
    border-bottom: 2px solid var(--dark-grey);
    width: 200px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    color: var(--darker-grey);
    margin: 0 auto;
    margin-top: 20px;
}

#imgUrl::placeholder{
    color: var(--light-grey);
}

#imgUrl:focus{
    outline: none;
}

.seperator{
    width: 1px;
    height: 80%;
    display: inline-block;
    border-left: 3px dashed var(--dark-grey);
}

#trueSearch{
    margin-top: 50px;
    min-width: 200px;
}

.result-count{
    color: var(--dark-grey);
    margin-top: 5px;
    margin-left: 10px;
    text-align: left;
    display: block;
}

.edit-canvas{
    padding: 0px;
    float: left;
}

.canvas-draw{
    margin: 0 auto;
    margin-top: -50px;
}

.cursor-div{
    background-color: black;
    position: absolute;
    border-radius: 50%;
}

.sketch-field-wrapper{
    border: 1px solid blue;
    display: block;
}

.edit-search-pane{
    position: absolute !important;
    left: 0;
    top:0;
    display: none;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 50px;
    overflow-y: hidden;
    background: rgba(255,255,255,.95);
}

.edit-search-pane.show{
    display: block;
}

#out-canvas{
    display: none;
}

.load-search{
    font-size: 20em;
    margin-top: 100px;
    color: lightgrey;
    opacity: .4;
}

#img-upload-load{
    margin-left: 10px;
    display: none;
}


#img-upload-load.show{
    display: inline-block;
}
