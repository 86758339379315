.admin-page-inner{
    width: 60%;
    min-width: 500px;
    margin: 0 auto;
}

.key-input-admin{
    font-size: 1em;
    width: 60%;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--darker-grey);
    display: flex;
}

.key-input-admin::placeholder{
    color: var(--light-grey);
}

.clear-icon-admin {
    height: 1em;
}

.clear-btn-admin {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear-btn-admin:hover {
    background-color: var(--light-grey);
}

.delete-btn-admin {
    color: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-btn-admin:hover {
    background-color: var(--light-grey);
}

.btn-primary-admin {
    display: flex;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.api-key-row {
    display: flex;
}

.admin-header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
