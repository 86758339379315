*{
    --label-grey: #969595;
    --panel-height: 450px;
}

.full-result-wrapper{
    display: inline-block;
    position: static;
}

.result-wrapper{
    vertical-align: top;
    margin: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    transition: .2s -webkit-box-shadow ease, .2s box-shadow ease,
        .2s -moz-box-shadow ease;
}

.result-wrapper:hover{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
}

.result-wrapper.focus{
    margin-bottom: 0px;
    border-bottom: 10px solid var(--tcam-blue);
}

.result-img{
    height: 175px;
    width: 200px;
    opacity: 0;
    transition: opacity 1s ease;
}

.result-img.show, .result-img.hosted.show{
    width: auto;
    opacity: 1;
}

.result-label-score{
    margin-right: 10px;
    font-weight: bolder;
    font-style: italic;
}


.result-error-text{
    position: absolute;
    
    display: none;

    font-size: 1.5em;
    margin: 0 auto;
    text-align: center;
    width: 200px;
    
    top: 50px;
}

.result-error-text.show{
    display: block;
}

.placeholder {
    height: 175px;
    width: 240px;
    width: auto !important;
    position: relative;
}

.placeholder.na{
    width: 200px !important;
}

.select {
  position: absolute;
  height: 22px;
  width: 22px;
  top : 7px;
  right: 7px;
  opacity: .5;
  color: white;
  display: block;
  margin: 0 auto;
}

.select:hover, .select.active {
  color: var(--tcam-blue);
  opacity: .5;
}

.select.active{
    opacity: 1;
}

.placeholder.active .select {
    background-color: var(--tcam-blue);
    opacity: 1;
}

.placeholder.dynamic {
    /* width: auto; */
    background-image: none;
}

.placeholder.dynamic-hosted {
    /* width: auto; */
    background-image: none;
    text-align: left;
}

.result-label{
    font-weight: bold;
    color: white;
    background-color: var(--label-grey);

    text-align: left;

    max-width: 100% !important;
    height: 20px;

    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;

}

.result-label-inner{
    margin-left: 5px;
    flex-grow: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-shifter{
    height: 0px;
    width: 1px;
    display: none;
    transition: .4s height ease;
}

.page-shifter.show{
    display: block;
    height: var(--panel-height);
}
