.config-content{
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: 100%;
    overflow-y: scroll;
}

.config-content-header{
    font-size: 1.8em;
    font-weight: bold;
}

.config-content-subheader{
    font-size: .9em;
    margin-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--dark-grey);
}

.config-content-footer{
    border-top: 2px solid var(--dark-grey);
    padding-top: 10px;
}

.config-content-body{
    position: relative;
    height: 80%;
    overflow-y: scroll;
}

.config-elem{
    padding-left: 20px;
    border-bottom: 1px solid var(--lighter-grey);
}

.config-elem-label{
    display: inline-block;
    font-weight: bold;
    margin-right: 20px;
}

.config-elem-text-inp, .config-elem-num-inp{
    font-size: 1em;
    padding-left: 5px;
}

.config-elem-num-inp{
    width: 60px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

.bool-switch-wrapper{
    display: inline-flex;
}

.bool-switch{
    margin-left: 5px;
    margin-right: 5px;
}

#config-save-loader{
    margin-left: 10px;
    display: none;
}