.optionbar ul{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    margin: 0px;
    display: inline-block;
}

.optionbar {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0,0,0,.77);
    color: #ffffff;
    font-size: 12px;
}

#update-search-text{
    display: none;
    font-style: italic;
    color: var(--lighter-grey);
    font-size: 1.2em;
}

.optionbar ul li {
    display: inline-block;
}

.optionbar ul li a {
    text-decoration: none;
    color: #ffffff;
}

.optButton{
    margin-left: 4px;
    margin-right: 3px;
}

.optButton a.toggled{
    font-weight: bolder;
    font-size: 1.1em;
}

.report-link{
    font-weight: bold;
    color: white;
    text-decoration: none;
    float: right;
    margin-right: 20px;
}

.report-link:hover{
    cursor: pointer;
}

.report-dl-icon{
    margin-left: 5px;
    font-size: 1em;
}

.locked-search-icon{
    margin-right: 10px;
}

#locked-search-text{
    font-size: 1.1em;
    font-weight: bold;
}
