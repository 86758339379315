
.tutorialsection {
  width: 80vw;
  height: 100%;
  overflow: scroll;
  display: inline-block;
  text-align: left;
}
.tutorialsection h1 {
  text-align: center;
}
.tutorialconent {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 20vh;
}
.contents {
  height: calc(100vh - 40px);
  overflow: hidden;
}
.tutorial-image {
  max-width: 960px;
  min-width: 720px;
  display: block;
  margin: auto;
}
