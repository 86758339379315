.report-hotel-ra{
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
    padding: 10px;
    color: var(--darker-grey);
    cursor: move;
    background-color: white;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rearrange-toggle{
    margin-right: 10px;
}
