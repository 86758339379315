.content-section {
    overflow-y: auto;
}

.bulk-loader{
    padding-top: 4vh;
    padding-bottom: 4vh;
    width:  80%;
    margin: 0 auto;
    max-width: 980px;
}

.places-search {
    height: 300px;
}

.drop-area{
    height: 320px;
    border: 2px solid black;
    margin-top: 6px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
}
.upload-container{
    width: 100%;
    display: grid;
    grid-row-gap: 6px;
    grid-column-gap: 26px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.placeholder-container{
    width: 100%;
    height: 90%
}

.placeholder-flexbox{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#drop-area-help-text{
    color: var(--light-grey);
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-top: -20px;
}

.upload-placeholder-wrapper{
    display: inline-block;
    width: 200px;
}

.upload-placeholder-image{
    max-height: 200px;
    max-width: 200px;
}

.image-elem{
    height: 120px;
    width: auto;
    max-width: 168px;
}
#upload-map {
    height: 300px;
    width: 100%;
}

/* Optional: Makes the sample page fill the window. */
html, body {
/*
    height: 100%;
    margin: 0;
    padding: 0;
*/
}

#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    width: 80%;
    height: 2.5em;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
    margin-top: 8px;
}

#pac-container {
    height: 100%;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    border: none;
}

.image-counter{
    margin-top: 1.5em;
}

#pac-input:focus {
    border: none;
    outline: none;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

#placeIDLabel {
    font-weight: bold;
}

#place-icon {
    margin-right: 4px;
}

#file-selector-button {
    width: 170px;
}

#file-selector-button input[type=file] {
    display:none;
}

#submit-images-button {
    width: 170px;
}

#submit-images-button:disabled:hover{
    cursor: not-allowed;
}

#submit-images-button:disabled{
    opacity: 0.3;
}

#controls-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 28px;
}

.hotel-info-card {

}

.hotel-info-input {
    width: 60%;
    margin-bottom: 10px;
    height: 1.2em;
}

.hotel-info-label {
    font-weight: bold;
}

.top-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 30%;
    grid-column-gap: 28px;
}

#roomNumberInput::placeholder {
    color: red;
    opacity: 0.8;
}

.successMsg {
    color: green;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-top: -20px;
}

.successMsg:hover {
    cursor: pointer;
}

.errorMsg {
    color: red;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-top: -20px;
}

.errorMsg:hover {
    cursor: pointer;
}

.uploading {
    
}

.uploading-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 15%;
}

.disabled-button {
    opacity: .3;
}

.disabled-button:hover {
    cursor: not-allowed !important;
    background-color: #3498db !important;
}

.image-wrapper{
    position: relative;
    text-align: right;
}

.remove-image {
    height: 20px;
    width: 20px;
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: .5;
    color: white;
    font-weight: bold;
    text-align: center;
}

.remove-image:hover{
    cursor: pointer;
    opacity: 1;
    background-color: rgba(91, 177, 219, .5);
}

